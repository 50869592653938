import "./App.css";
import Freelance from "./components/Freelance";

import Landing from "./components/Landing";
import Myprojects from "./components/Myprojects";
import Resume from "./components/Resume";
import ReactGA from "react-ga4";

ReactGA.initialize("G-0BC1Y8VKB8");
ReactGA.send("pageview");

function App() {
  return (
    <div>
      <div id="header">
        <div>Diangelo</div>
      </div>
      <Landing />
      <Freelance />
      <Myprojects />
      <Resume />
    </div>
  );
}

export default App;
