import React from "react";

function Myprojects() {
  return (
    <div className="">
      <span className="flex justify-center my-8" id="myproject-title">
        My<span id="myproject-title2">Personal</span>{" "}
      </span>

      {/* Recipe Finder */}
      <div className="flex flex-grow flex-col md:flex-row justify-center items-center mb-12">
        <div className="left-side md:mr-8" id="leftside-background">
          <img src="/images/recipefinder.png" alt="recipe-finder" />
        </div>

        <div className="">
          <h3 className="project-title-text">Recipe Finder</h3>
          <ul className="flex flex-row  items-center">
            <p className="inline-block mr-3.5" id="made-with">
              Made with:{" "}
            </p>
            <li className="inline-block mr-3.5">
              <span
                class="iconify"
                data-icon="vscode-icons:file-type-js-official"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="vscode-icons:file-type-reactjs"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="logos:nodejs"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="logos:tailwindcss-icon"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="vscode-icons:file-type-mongo"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="skill-icons:expressjs-light"
                data-width="25"
                data-height="25"
              ></span>
            </li>
          </ul>

          <p className="project-description mt-2">
            Search for recipes on Recipe Finder. You can search by the
            ingredients available to you, by diet, or by recipe title. Created
            in MERN stack. Front end using tailwindcss. Data in DB scraped using
            selenium.
          </p>
          <div className="mt-4">
            <a
              href="https://hosted-frontend--jocular-sunburst-256ac2.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center mr-2"
            >
              <span class="iconify" data-icon="carbon:new-tab"></span>
              <span>Live</span>
            </a>

            <a
              href="https://docs.google.com/document/d/1hY-8b5sV7tIkQ-DqDBPDLJkjRi28kIj_FHm9Wa25NeM/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center mr-2"
            >
              <span class="iconify" data-icon="carbon:new-tab"></span>
              <span>Tutorial</span>
            </a>

            <a
              href="https://github.com/diang-elo/Recipe-Matching-System"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center"
            >
              <span class="iconify" data-icon="ant-design:github-filled"></span>
              <span>Github</span>
            </a>
          </div>
        </div>
      </div>

      {/* The Cutting Board */}
      <div className="flex flex-grow flex-col md:flex-row justify-center items-center mb-12">
        <div className="left-side md:mr-8" id="leftside-background">
          <img src="/images/cutting-board.png" alt="first-project" />
        </div>

        <div className="">
          <h3 className="project-title-text">The Cutting Board</h3>
          <ul className="flex flex-row  items-center">
            <p className="inline-block mr-3.5" id="made-with">
              Made with:{" "}
            </p>
            <li className="inline-block mr-3.5">
              <span
                class="iconify"
                data-icon="vscode-icons:file-type-js-official"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="vscode-icons:file-type-reactjs"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="logos:nodejs"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="logos:tailwindcss-icon"
                data-width="25"
                data-height="25"
              ></span>
            </li>
          </ul>

          <p className="project-description mt-2">
            Search your favorite recipes on The Cutting Board. Learn the
            category and background of your searched dish, in addition to a step
            by step recipe and video tutorial on how to make it. Created in
            react, with the axios, react-router-dom, and tailwindcss libraries.
          </p>
          <div className="mt-4">
            <a
              href="https://subtle-queijadas-875ee8.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center mr-2"
            >
              <span class="iconify" data-icon="carbon:new-tab"></span>
              <span>Live</span>
            </a>

            <a
              href="https://github.com/diang-elo/frontend-tutorial"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center"
            >
              <span class="iconify" data-icon="ant-design:github-filled"></span>
              <span>Github</span>
            </a>
          </div>
        </div>
      </div>

      {/* Releif */}
      <div className="flex flex-grow flex-col md:flex-row justify-center items-center mb-12">
        <div className="left-side md:mr-8" id="leftside-background">
          <img src="/images/releif.png" alt="first-project" />
        </div>

        <div className="">
          <h3 className="project-title-text">RELEIF</h3>
          <ul className="flex flex-row  items-center">
            <p className="inline-block mr-3.5" id="made-with">
              Made with:{" "}
            </p>
            <li className="inline-block mr-3.5">
              <span
                class="iconify"
                data-icon="vscode-icons:file-type-js-official"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="vscode-icons:file-type-reactjs"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="logos:nodejs"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="vscode-icons:file-type-firebase"
                data-width="25"
                data-height="25"
              ></span>
            </li>
          </ul>

          <p className="project-description mt-2">
            RELIEF is a place to take a step back, relax, and collect yourself
            before getting back into your daily routine. RELIEF has multiple
            stress relieving options including meditation, gameplay, and an
            interactive chat room.
          </p>
          <div className="mt-4">
            <a
              href="https://kind-noyce-15320b.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center mr-2"
            >
              <span class="iconify" data-icon="carbon:new-tab"></span>
              <span>Live</span>
            </a>

            <a
              href="https://devpost.com/software/the-escape-from-the-rat-race"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center mr-2"
            >
              <span class="iconify" data-icon="simple-icons:devpost"></span>
              <span>Devpost</span>
            </a>

            <a
              href="https://github.com/diang-elo/hackottowa"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center"
            >
              <span class="iconify" data-icon="ant-design:github-filled"></span>
              <span>Github</span>
            </a>
          </div>
        </div>
      </div>

      {/* TMU Ad */}
      <div className="flex flex-grow flex-col md:flex-row justify-center items-center mb-12">
        <div className="left-side md:mr-8" id="leftside-background">
          <img src="/images/kijiji.png" alt="kijiji-clone" />
        </div>

        <div className="">
          <h3 className="project-title-text">TMU Marketplace Clone</h3>
          <ul className="flex flex-row  items-center">
            <p className="inline-block mr-3.5" id="made-with">
              Made with:{" "}
            </p>
            <li className="inline-block mr-3.5">
              <span
                class="iconify"
                data-icon="vscode-icons:file-type-js-official"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="vscode-icons:file-type-reactjs"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="logos:nodejs"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="devicon:digitalocean"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="logos:tailwindcss-icon"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="vscode-icons:file-type-mongo"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="skill-icons:expressjs-light"
                data-width="25"
                data-height="25"
              ></span>
            </li>
          </ul>

          <p className="project-description mt-2">
            A Kijiji-style platform for Toronto Metropolitan University
            students, enabling item listing and searching within the TMU
            community. Built with the MERN stack, using MongoDB for data
            storage, JWT for secure authentication, and DigitalOcean for image
            hosting.
          </p>
          <div className="mt-4">
            <a
              href="https://wondrous-marshmallow-ed580e.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center mr-2"
            >
              <span class="iconify" data-icon="carbon:new-tab"></span>
              <span>Live</span>
            </a>

            <a
              href="https://github.com/diang-elo/TMU-Classifieds-Web-Interface"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center"
            >
              <span class="iconify" data-icon="ant-design:github-filled"></span>
              <span>Github</span>
            </a>
          </div>
        </div>
      </div>

      {/* Recycle it */}
      <div className="flex flex-grow flex-col md:flex-row justify-center items-center mb-12">
        <div className="left-side md:mr-8" id="leftside-background">
          <img src="/images/recycleit1.png" alt="first-project" />
        </div>

        <div className="">
          <h3 className="project-title-text">RECYCLE IT</h3>
          <ul className="flex flex-row  items-center">
            <p className="inline-block mr-3.5" id="made-with">
              Made with:{" "}
            </p>
            <li className="inline-block mr-3.5">
              <span
                class="iconify"
                data-icon="vscode-icons:file-type-flutter"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="vscode-icons:file-type-reactjs"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="logos:nodejs"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="logos:bootstrap"
                data-width="25"
                data-height="25"
              ></span>
            </li>
          </ul>

          <p className="project-description mt-2">
            Mobile application that allows users to scan any barcode, receive a
            price, and get directions to the closest recycling depot. Created in
            flutter, utilising barcode monster API, Ebay price API, and Google
            Maps API. Website for advertisement built with React and Bootsrap.
          </p>
          <div className="mt-4">
            <a
              href="https://recycleit.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center mr-2"
            >
              <span class="iconify" data-icon="carbon:new-tab"></span>
              <span>Live</span>
            </a>

            <a
              href="https://devpost.com/software/recycleit-ke590l"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center mr-2"
            >
              <span class="iconify" data-icon="simple-icons:devpost"></span>
              <span>Devpost</span>
            </a>

            <a
              href="https://github.com/diang-elo/recycleitapp"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center mr-2"
            >
              <span class="iconify" data-icon="ant-design:github-filled"></span>
              <span>Github</span>
            </a>

            <a
              href="https://www.youtube.com/watch?v=PAU6nhE7tFM"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="demo-button text-white font-bold py-2 px-4 inline-flex items-center"
            >
              <span
                class="iconify"
                data-icon="ant-design:youtube-filled"
              ></span>
              <span>Demo</span>
            </a>
          </div>
        </div>
      </div>

      {/* Battleship*/}
      <div className="flex flex-grow flex-col md:flex-row justify-center items-center mb-12">
        <div className="left-side md:mr-8" id="leftside-background">
          <img src="/images/battleship.png" alt="first-project" />
        </div>

        <div className="">
          <h3 className="project-title-text">Battleship</h3>
          <ul className="flex flex-row  items-center">
            <p className="inline-block mr-3.5" id="made-with">
              Made with:{" "}
            </p>
            <li className="inline-block mr-3.5">
              <span
                class="iconify"
                data-icon="logos:html-5"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="logos:css-3"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="vscode-icons:file-type-js-official"
                data-width="25"
                data-height="25"
              ></span>
            </li>
          </ul>

          <p className="project-description mt-2">
            Classic Battleship game using HTML, CSS, and JavaScript. Two-player
            mode: one player is the user, and the other is the computer (PC). An
            interactive, browser-based version of this timeless game.
          </p>
          <div className="mt-4">
            <a
              href="https://65f1f0e2056959120dc99a76--snazzy-kheer-25b231.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center mr-2"
            >
              <span class="iconify" data-icon="carbon:new-tab"></span>
              <span>Live</span>
            </a>

            <a
              href="https://github.com/diang-elo/battleship"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center"
            >
              <span class="iconify" data-icon="ant-design:github-filled"></span>
              <span>Github</span>
            </a>
          </div>
        </div>
      </div>

      {/* Movie Award Website */}
      <div className="flex flex-grow flex-col md:flex-row justify-center items-center mb-12">
        <div className="left-side md:mr-8" id="leftside-background">
          <img src="/images/imdbweb.png" alt="first-project" />
        </div>

        <div className="">
          <h3 className="project-title-text">RELEIF</h3>
          <ul className="flex flex-row  items-center">
            <p className="inline-block mr-3.5" id="made-with">
              Made with:{" "}
            </p>
            <li className="inline-block mr-3.5">
              <span
                class="iconify"
                data-icon="logos:html-5"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="logos:css-3"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="vscode-icons:file-type-js-official"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="eos-icons:api"
                data-width="25"
                data-height="25"
              ></span>
            </li>
          </ul>

          <p className="project-description mt-2">
            Static website (written with HTML, CSS, and JS) using OMDB’s API
            which allows users to nominate their 5 favorite films/television
            shows. All icons, banners, and symbols were created in Illustrator
            and Photoshop.
          </p>
          <div className="mt-4">
            <a
              href="https://iridescent-syrniki-76b8fc.netlify.app"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center mr-2"
            >
              <span class="iconify" data-icon="carbon:new-tab"></span>
              <span>Live</span>
            </a>

            <a
              href="https://github.com/diang-elo/imdb-app1"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center"
            >
              <span class="iconify" data-icon="ant-design:github-filled"></span>
              <span>Github</span>
            </a>
          </div>
        </div>
      </div>

      {/* think easy */}
      <div className="flex flex-grow flex-col md:flex-row justify-center items-center mb-12">
        <div className="left-side md:mr-8" id="leftside-background">
          <img src="/images/thinkez.png" alt="first-project" />
        </div>

        <div className="">
          <h3 className="project-title-text">Think Easy</h3>
          <ul className="flex flex-row  items-center">
            <p className="inline-block mr-3.5" id="made-with">
              Made with:{" "}
            </p>
            <li className="inline-block mr-3.5">
              <span
                class="iconify"
                data-icon="vscode-icons:file-type-js-official"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="vscode-icons:file-type-reactjs"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="logos:nodejs"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="logos:redux"
                data-width="25"
                data-height="25"
              ></span>
            </li>
          </ul>

          <p className="project-description mt-2">
            Website made with React JS and Redux. Utilises Redux store,
            reducers, and actions. The purpose of the website is for the user to
            input who, what, where, when. After all fields are inputted, the
            user will be presented with a complete sentence of all the inputted
            information.
          </p>
          <div className="mt-4">
            <a
              href="https://thirsty-swartz-c45488.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center mr-2"
            >
              <span class="iconify" data-icon="carbon:new-tab"></span>
              <span>Live</span>
            </a>

            <a
              href="https://github.com/diang-elo/redux-project"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center mr-2"
            >
              <span class="iconify" data-icon="ant-design:github-filled"></span>
              <span>Github</span>
            </a>

            <a
              href="https://www.youtube.com/watch?v=vOpnxXQiCH4"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center"
            >
              <span
                class="iconify"
                data-icon="ant-design:youtube-filled"
              ></span>
              <span>Demo</span>
            </a>
          </div>
        </div>
      </div>

      {/* todo list */}
      <div className="flex flex-grow flex-col md:flex-row justify-center items-center mb-12">
        <div className="left-side md:mr-8" id="leftside-background">
          <img src="/images/todo.png" alt="first-project" />
        </div>

        <div className="">
          <h3 className="project-title-text">TODO LIST</h3>
          <ul className="flex flex-row  items-center">
            <p className="inline-block mr-3.5" id="made-with">
              Made with:{" "}
            </p>
            <li className="inline-block mr-3.5">
              <span
                class="iconify"
                data-icon="vscode-icons:file-type-js-official"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="vscode-icons:file-type-reactjs"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="logos:nodejs"
                data-width="25"
                data-height="25"
              ></span>
            </li>
          </ul>

          <p className="project-description mt-2">
            Todo list where you are able to add and delete items. Sort by
            all/complete/incomplete.
          </p>
          <div className="mt-4">
            <a
              href="https://todo-app-one.herokuapp.com/"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center mr-2"
            >
              <span class="iconify" data-icon="carbon:new-tab"></span>
              <span>Live</span>
            </a>

            <a
              href="https://github.com/diang-elo/todo-app01"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center mr-2"
            >
              <span class="iconify" data-icon="ant-design:github-filled"></span>
              <span>Github</span>
            </a>
          </div>
        </div>
      </div>

      {/* Connect 4 */}
      <div className="flex flex-grow flex-col md:flex-row justify-center items-center mb-12 pb-12">
        <div className="left-side md:mr-8" id="leftside-background">
          <img src="/images/connect4.png" alt="first-project" />
        </div>

        <div className="">
          <h3 className="project-title-text">Connect 4</h3>
          <ul className="flex flex-row  items-center">
            <p className="inline-block mr-3.5" id="made-with">
              Made with:{" "}
            </p>
            <li className="inline-block mr-3.5">
              <span
                class="iconify"
                data-icon="logos:java"
                data-width="25"
                data-height="25"
              ></span>
            </li>
          </ul>

          <p className="project-description mt-2">
            Connect four written in Java with JavaFX GUI. Two modes, PVP and
            PVC. The goal of the game is to connect four of your pieces in a
            row. Uses object-oriented principles and classes.
          </p>
          <div className="mt-4">
            <a
              href="https://github.com/NathanZC/NathanDiangeloConnect4"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center mr-2"
            >
              <span class="iconify" data-icon="ant-design:github-filled"></span>
              <span>Github</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Myprojects;
