import React from "react";

function Freelance() {
  return (
    <div className="">
      <span className="flex justify-center my-8" id="myproject-title">
      My<span id="myproject-title2">Professional</span>{" "}
      </span>

      {/* Dulcinella */}
      <div className="flex flex-grow flex-col md:flex-row justify-center items-center mb-12">
        <div className="left-side md:mr-8" id="leftside-background">
          <img src="/images/dulcinella.png" alt="recipe-finder" />
        </div>

        <div className="">
          <h3 className="project-title-text">Dulcinella</h3>
          <ul className="flex flex-row  items-center">
            <p className="inline-block mr-3.5" id="made-with">
              Made with:{" "}
            </p>
            <li className="inline-block mr-3.5">
              <span
                class="iconify"
                data-icon="devicon:typescript"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="ri:nextjs-fill"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="logos:tailwindcss-icon"
                data-width="25"
                data-height="25"
              ></span>
            </li>
            <li className="inline-block mr-3.5">
              <span></span>
              <span
                class="iconify"
                data-icon="logos:google-analytics"
                data-width="25"
                data-height="25"
              ></span>
            </li>
          </ul>

          <p className="project-description mt-2">
            Designed, implemented, and hosted the Dulcinella website, a digital
            home for a family-owned bakery in the Beaches, Toronto. Built with
            Next.js, TypeScript, and Tailwind CSS, the site showcases their
            handcrafted cakes and pastries while emphasizing their rich
            traditions and local roots. Featuring a seamless user experience, an
            engaging gallery, and a heartfelt "Our Story" section, the website
            connects the community with the sweet flavors of Dulcinella.
          </p>
          <div className="mt-4">
            <a
              href="http://dulcinella.ca/"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center mr-2"
            >
              <span class="iconify" data-icon="carbon:new-tab"></span>
              <span>Live</span>
            </a>

            <a
              href="https://www.figma.com/design/gscTZLBKkzihqy6VoYGVuh/Dulcinella?node-id=0-1&t=lMGe7yjNtqa6ftQH-1"
              target="_blank"
              rel="noopener noreferrer"
              id="project-button"
              class="text-white font-bold py-2 px-4 inline-flex items-center mr-2"
            >
              <span class="iconify" data-icon="carbon:new-tab"></span>
              <span>Figma</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Freelance;
